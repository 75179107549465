const DEFAULT_META = {
  nl: {
    description:
      'Wij zijn een software en data partner voor ambitieuze organisaties die vastbesloten zijn om te innoveren door data en technologie.',
    title: 'Researchable | Innovation through data',
    // the image gets prefixed with https://researchable.nl always.
    image: '/assets/logo/logo-single-square.png',
  },

  en: {
    description:
      'We are a software and data partner for ambitious organisations that are committed to innovate through data and technology.',
    title: 'Researchable - Innovation through data',
    image: '/assets/logo/logo-single-square.png',
  },
}

interface Props {
  description?: string
  title?: string
  image?: string
  locale: string
  children?: React.ReactNode
}

const MetaInformation: React.FC<Props> = (props) => {
  const { children, locale } = props

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://researchable.nl',
    logo: 'https://researchable.nl/assets/logo/logo-single-square.png',
  }

  const title = props.title || DEFAULT_META[locale].title
  const description = props.description || DEFAULT_META[locale].description
  const image = props.image || DEFAULT_META[locale].image

  return (
    <>
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#00B1E9" />

      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content={description} />
      <meta content="IE=edge,chrome=1" httpEquiv="X-UA-Compatible" />

      <meta name="theme-color" content="#00B1E9" />
      <meta name="msapplication-TileColor" content="#00b1e9" />
      <meta
        name="msapplication-TileImage"
        content="https://researchable.nl/assets/logo/logo-single-square.png"
      />
      <meta name="application-name" content={title} />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <meta name="apple-mobile-web-app-title" content={title} />

      <meta property="og:type" content="business.business" />
      <meta
        property="business:contact_data:street_address"
        content="Zernikepark 12"
      />
      <meta property="business:contact_data:locality" content="Groningen" />
      <meta property="business:contact_data:region" content="Groningen" />
      <meta property="business:contact_data:postal_code" content="9747AN" />
      <meta
        property="business:contact_data:country_name"
        content="Netherlands"
      />
      <meta property="og:title" content={title} />
      <meta
        property="og:site_name"
        content="Researchable - Innovation through data"
      />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content="en" />
      <meta property="og:image" content={`https://researchable.nl${image}`} />
      <meta
        property="og:image:secure_url"
        content={`https://researchable.nl${image}`}
      />
      <meta property="og:image:width" content="500" />
      <meta property="og:image:height" content="500" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:site" content="@researchablenl" />

      <meta name="twitter:image" content={`https://researchable.nl${image}`} />
      <meta name="twitter:creator" content="@researchablenl" />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      />
      {children}
    </>
  )
}

export default MetaInformation
