import { ReactSVG } from 'react-svg'
import { memo } from 'react'

export interface SvgImageProps {
  src: string
  className?: string
}

const SvgImage = memo(({ src, className }: SvgImageProps) => {
  return <ReactSVG src={src} className={className} />
})

export default SvgImage
