import Link from 'next/link'
import { useRouter } from 'next/router'
import { twMerge } from 'tailwind-merge'

interface QuoteLinkProps {
  href: string
  title: string
  subtitle?: string
}

const QuoteLink = ({ href, title, subtitle }: QuoteLinkProps) => {
  const { asPath } = useRouter()
  const isActive = new RegExp(`^${href}`).test(asPath)

  const cls = twMerge(
    'px-4 border-s-2 transition-all duration-300',
    'hover:border-blue', // bright, light and transparent theme
    'black:border-white/25 black:hover:border-blue', // black theme
    'transp-black:border-white/25 transp-black:hover:border-blue', // black theme
    isActive ? 'border-blue' : 'border-gray-900/25 ',
  )
  return (
    <Link href={href}>
      <div className={cls}>
        <p className="text-bold mb-2">{title}</p>
        <p className="text-xs opacity-50 leading-relaxed">{subtitle}</p>
      </div>
    </Link>
  )
}

export default QuoteLink
