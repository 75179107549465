import classNames from 'classnames'

type Props = {
  onClick: () => void
  className?: string
  type: 'default' | 'close' | 'back'
}

const makeClasses = (top: boolean, type: Props['type']) =>
  classNames(
    'transition ease-in-out transform duration-300 absolute left-0 inline-block bg-current w-full h-0.5',
    {
      'scale-90': type === 'close',
      'scale-x-[70%]': type === 'back',

      'top-1': top,
      'bottom-1': !top,

      'rotate-45': top && type === 'close',
      '-rotate-45': !top && type === 'close',

      'rotate-[40deg]': !top && type === 'back',
      '-rotate-[40deg]': top && type === 'back',

      'translate-y-1.25': top && type === 'close',
      '-translate-y-1.25': !top && type === 'close',

      'translate-y-[1px]': top && type === 'back',
      '-translate-y-[1px]': !top && type === 'back',
    },
  )

const MobileMenuToggle = ({
  onClick,
  className = '',
  type = 'default',
}: Props) => {
  const cls1 = makeClasses(true, type)
  const cls2 = makeClasses(false, type)

  return (
    <button aria-label="Mobile menu" className={className} onClick={onClick}>
      <div className="relative w-5 h-5">
        <span className={cls1}></span>
        <span className={cls2}></span>
      </div>
    </button>
  )
}

export default MobileMenuToggle
