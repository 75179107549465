import FooterSocials from '../molecules/FooterSocials'
import NavItems from '../molecules/NavItems'

const MobileMenu = () => {
  return (
    <div className="flex flex-col items-center gap-8 justify-between h-full pb-8 ">
      <NavItems className="flex-col grow-0" />
      <FooterSocials />
    </div>
  )
}

export default MobileMenu
