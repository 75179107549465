import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons'
import Button from './Button'
import NextLink from 'next/link'

type Type = 'twitter' | 'facebook' | 'instagram' | 'linkedin'

type Props = {
  icon: Type
  href: string
  className?: string
}

const getIcon = (icon: Type) => {
  switch (icon) {
    case 'twitter':
      return faXTwitter
    case 'facebook':
      return faFacebookSquare
    case 'instagram':
      return faInstagram
    case 'linkedin':
      return faLinkedin
  }
}

const SocialCircle = ({ icon, href, className }: Props) => {
  return (
    <NextLink href={href} target="_blank" rel="noreferrer">
      <Button
        size="icon"
        variant="secondary"
        className={className}
        contentClassName="flex justify-center items-center"
      >
        <FontAwesomeIcon className="text-xl" icon={getIcon(icon)} />
      </Button>
    </NextLink>
  )
}

export default SocialCircle
