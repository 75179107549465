import SocialCircle from '../atoms/SocialCircle'
import { attributes } from '../content/footer.md'
import { useLocale } from '../hooks/useLocale'
import { FooterContents } from '../content/types.d'

type Props = {
  className?: string
}

const FooterSocials = ({ className }: Props) => {
  const { translations } = useLocale<FooterContents>(attributes)

  return (
    <div className={`${className ?? ''} flex flex-row gap-2`}>
      {/* socialprops from translations has an `href` and an `icon` attribute */}
      {translations.socials.map((socialprops) => (
        <SocialCircle key={socialprops.icon} {...socialprops} />
      ))}
    </div>
  )
}

export default FooterSocials
