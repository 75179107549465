type Props = {
  title: string
  children: React.ReactNode
  className?: string
}

const FooterLinkCol = ({ title, children, className }: Props) => {
  return (
    <div className={`flex flex-col ${className ?? ''}`}>
      <h3 className="leading-loose text-lg font-medium mb-4">{title}</h3>
      {children}
    </div>
  )
}

export default FooterLinkCol
