import {
  NavigationMenuItem,
  NavigationMenuLink,
} from 'ui/NavigationMenu'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  to: string
  children: ReactNode
}

const NavItem = ({ to, children }: Props) => {
  const { asPath } = useRouter()
  const isActive = new RegExp(`^${to}`).test(asPath)
  const underline = (
    <span
      className={twMerge(
        'block mt-1 max-w-0 group-hover:max-w-full duration-200 h-0.5 bg-blue',
        isActive && 'max-w-full',
      )}
    />
  )
  const itemCls = 'group transition duration-50 h-full'

  return (
    <NavigationMenuItem className="py-6">
      <NavigationMenuLink asChild>
        <Link href={to} className={itemCls}>
          {children}
          {underline}
        </Link>
      </NavigationMenuLink>
    </NavigationMenuItem>
  )
}

export default NavItem
