import { useLocale } from '@/hooks/useLocale'
import React from 'react'

enum Locale {
  EN = 'en',
  NL = 'nl',
}

const LanguageSwitch = () => {
  const { locale, changeLocale } = useLocale()

  function toggleLocale() {
    changeLocale(locale === Locale.EN ? Locale.NL : Locale.EN)
  }

  return (
    <button
      className="border-2
      h-12 w-12 flex rounded-full items-center justify-center text-xs font-medium
      transition-colors duration-200
      bright:text-black bright:hover:text-white bright:hover:bg-black bright:hover:border-opacity-100 bright:border-gray-900/25
      light:text-black light:hover:text-white light:hover:bg-black light:hover:border-opacity-100 light:border-gray-900/25
      transp-light:text-black transp-light:hover:text-white transp-light:hover:bg-black transp-light:hover:border-opacity-100 transp-light:border-gray-900/25
      border-white/25 text-white hover:bg-white hover:text-black hover:border-white"
      onClick={toggleLocale}
    >
      <span className="leading-none">{locale.toUpperCase()}</span>
    </button>
  )
}

export default LanguageSwitch
