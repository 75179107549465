import { FooterContents } from '@/content/types'
import { useLocale } from '@/hooks/useLocale'
import React from 'react'
import { attributes } from '../content/footer.md'
import Link from '@/atoms/Link'

const FooterContactInformation = () => {
  const { translations } = useLocale<FooterContents>(attributes)

  return (
    <section className="flex flex-col flex gap-8">
      <h2 className="font-medium">{translations.contact.company_name}</h2>
      <div className="flex flex-col gap-4 text-sm">
        <Link
          className="hover:text-blue"
          href={translations.contact.google_maps_url}
          variant="plain"
          icon="off"
        >
          {`${translations.contact.address} (${translations.contact.address_addition})`}
        </Link>
        <span>
          {`${translations.contact.postal_code} ${translations.contact.city}`}
        </span>
        <a
          className="hover:text-blue"
          href={`tel:${translations.contact.phone}`}
        >
          {translations.contact.phone}
        </a>
        <a
          className="hover:text-blue underline underline-offset-4"
          href={`mailto:${translations.contact.email}`}
        >
          {translations.contact.email}
        </a>
      </div>
    </section>
  )
}

export default FooterContactInformation
