import SvgImage from '@/atoms/SvgImage'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { twMerge } from 'tailwind-merge'

interface ImageCardLinkProps {
  href: string
  title: string
  svg: string
  className?: string
}

const ImageCardLink = ({ href, title, svg, className }: ImageCardLinkProps) => {
  const { asPath } = useRouter()
  const isActive = new RegExp(`^${href}`).test(asPath)

  const cls = twMerge(
    'flex lg:flex-col items-center p-3 lg:p-8 gap-4 group transition duration-300',
    'hover:bg-blue bright:hover:bg-blue light:hover:bg-blue hover:text-white hover:fill-white', // hover effect
    isActive && 'bg-blue bright:bg-blue light:bg-blue text-white fill-white', // background
    !isActive && 'bg-gray-700/20 bright:bg-gray-700 light:bg-white fill-blue ', // background
  )
  return (
    <Link href={href} className={className}>
      <div className={cls}>
        <SvgImage src={svg} className="w-16 lg:w-20" />
        <p className="text-center">{title}</p>
      </div>
    </Link>
  )
}

export default ImageCardLink
