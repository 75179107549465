import classNames from 'classnames'
import { Theme } from '../hooks/useTheme'

type Props = {
  theme?: Theme
  className?: string
  hideTextOnMobile?: boolean
}

const Logo = ({ theme, className = '', hideTextOnMobile }: Props) => {
  const cls = classNames(
    'fill-current flex-shrink-1 w-full max-w-logo transition duration-300',
    className,
    {
      'text-transparent': hideTextOnMobile,
      'text-white':
        !hideTextOnMobile &&
        (theme === 'black' ||
          theme === 'transp-black' ||
          theme === 'transp-blue'),
      'md:text-white':
        theme === 'black' ||
        theme === 'transp-black' ||
        theme === 'transp-blue',
      'text-black md:text-black':
        theme === 'light' ||
        theme === 'transp-light' ||
        theme === 'bright' ||
        theme === undefined,
      'black:text-white md:black:text-white': theme === undefined,
    },
  )

  const logocol = theme === 'transp-blue' ? '#FFF' : '#00B1E9'
  // This file is 5kb, maybe use a file instead? CSS hack probably won't work then..
  return (
    <svg
      viewBox="0 0 280 34"
      className={cls}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9407 32.1038L0.984871 15.1473C-0.32829 13.8347 -0.32829 11.6866 0.984871 10.374C2.29692 9.06081 4.44507 9.06081 5.75823 10.374L22.7141 27.3304C24.0273 28.643 24.0273 30.7911 22.7141 32.1038C21.4015 33.4164 19.2533 33.4164 17.9407 32.1038"
        fill={logocol}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.08766 32.1059V32.1059C-0.224941 30.7933 -0.224941 28.6452 1.08766 27.3326C2.40027 26.0194 4.54842 26.0194 5.86102 27.3326C7.17418 28.6452 7.17418 30.7933 5.86102 32.1059C4.54842 33.4185 2.40027 33.4185 1.08766 32.1059"
        fill={logocol}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1386 14.4955L9.40131 5.7582C8.08815 4.44504 8.08815 2.29744 9.40131 0.98484C10.7139 -0.328321 12.8621 -0.328321 14.1747 0.98484L22.912 9.72218C24.2246 11.0348 24.2246 13.1829 22.912 14.4955C21.5994 15.8081 19.4513 15.8081 18.1386 14.4955"
        fill={logocol}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.9975 12.7587V17.9579H61.3578C63.2616 17.9579 64.101 16.7505 64.101 15.399C64.101 14.1097 63.2616 12.7587 61.3784 12.7587H56.9975ZM67.0691 24.9997H64.3871L60.0274 20.0459H56.9975V24.9997H54.7051V10.6501C56.9362 10.6501 59.1467 10.6707 61.3784 10.6707C64.6944 10.6707 66.4345 12.9424 66.4345 15.3583C66.4345 17.4057 65.3702 19.4114 62.5451 19.8211L66.8231 24.5905L67.0691 24.9997Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.1618 24.9998H74.5786V10.6708H85.1006V12.9019H76.8715V16.7707H84.7727V18.8993H76.8715V22.7481H85.1618V24.9998Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.781 13.8432C101.106 12.8607 99.7343 12.2673 98.2397 12.2467C96.3159 12.2467 94.9237 13.0449 94.9237 14.4166C94.9237 15.8082 96.5202 16.1973 98.4039 16.4634C101.351 16.8725 104.156 17.6919 104.156 20.9061C104.135 24.1197 101.208 25.3276 98.2197 25.3276C95.4764 25.3276 93.3678 24.4882 92.058 22.0723L93.7775 20.9261C94.8006 22.6663 96.582 23.2803 98.2397 23.2803C99.9592 23.2803 101.863 22.7074 101.863 20.9061C101.863 19.3502 100.205 18.8181 98.1985 18.5514C95.3534 18.1629 92.6514 17.4465 92.6514 14.3754C92.6308 11.4072 95.5995 10.2605 98.1785 10.2605C100.246 10.2605 102.313 10.7521 103.521 12.6764L101.781 13.8432Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.433 24.9998H111.85V10.6708H122.372V12.9019H114.142V16.7707H122.044V18.8993H114.142V22.7481H122.433V24.9998Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.555 13.086L133.525 19.9028H139.584L136.555 13.086ZM140.423 21.9909H132.665L131.314 24.9996H128.878L135.306 10.6706H137.803L144.231 24.9996H141.795L140.423 21.9909Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153.624 12.7587V17.9579H157.984C159.888 17.9579 160.727 16.7505 160.727 15.399C160.727 14.1097 159.888 12.7587 158.005 12.7587H153.624ZM163.695 24.9997H161.013L156.654 20.0459H153.624V24.9997H151.331V10.6501C153.562 10.6501 155.773 10.6707 158.005 10.6707C161.321 10.6707 163.061 12.9424 163.061 15.3583C163.061 17.4057 161.996 19.4114 159.171 19.8211L163.449 24.5905L163.695 24.9997Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.262 23.1575C181.788 24.6109 179.884 25.2862 177.878 25.2862C172.679 25.2862 170.345 21.7041 170.325 17.9377C170.304 14.1708 172.761 10.425 177.878 10.425C179.803 10.425 181.665 11.1414 183.119 12.5943L181.583 14.0689C180.56 13.0653 179.209 12.5943 177.878 12.5943C174.235 12.5943 172.577 15.2763 172.597 17.9171C172.617 20.5373 174.173 23.1369 177.878 23.1369C179.209 23.1369 180.683 22.5847 181.686 21.581L183.262 23.1575Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M200.577 24.9998V19.0429H193.003V24.9998H190.71V10.6708H193.003V16.9143H200.577V10.6708H202.849V24.9998H200.577Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M217.626 13.086L214.597 19.9028H220.655L217.626 13.086ZM221.495 21.9909H213.737L212.386 24.9996H209.95L216.377 10.6706H218.875L225.302 24.9996H222.866L221.495 21.9909Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M234.675 22.871H238.974C240.242 22.871 242.003 22.4619 242.003 20.8854C242.003 19.3501 240.304 18.6543 238.994 18.6543H234.675V22.871ZM234.675 16.6069H238.994C240.713 16.6069 241.573 15.9311 241.573 14.8056C241.573 13.8025 240.693 12.7381 238.974 12.7381H234.675V16.6069ZM238.974 10.6707C241.696 10.6707 243.845 11.9193 243.845 14.7644C243.845 15.9723 243.21 17.0572 241.86 17.61C243.579 18.1216 244.296 19.8211 244.296 20.906C244.296 24.0378 241.798 24.9997 238.974 24.9997H232.402V10.6707H238.974Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M254.466 10.6707V22.871H262.02V24.9997H252.174V10.6707H254.466Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M279.888 24.9998H269.305V10.6708H279.827V12.9019H271.598V16.7707H279.499V18.8993H271.598V22.7481H279.888V24.9998Z"
      />
    </svg>
  )
}

export default Logo
